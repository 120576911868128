.body {
	background: unset;
}

.archive {
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
}

.archiveMain {
	flex: 1 1 auto;
}

.archiveMain > article {
	width: 100%;
	height: 100%;
}
