.archiveLink {
	display: inline-block;

	box-sizing: border-box;
	width: 100%;

	border: var(--primary-inverted) 1px solid;
	color: inherit;
	text-decoration: none;

	font-size: 4rem;

	will-change: color, background;
	transition: color, background var(--transition-time) linear;
}

.archiveLink:hover,
.archiveLink:focus {
	opacity: unset;

	color: var(--primary);
	background: var(--primary-inverted);
}

.innerPadding {
	padding: 6rem 1rem 1rem 1rem;
}
