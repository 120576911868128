:root {
	--primary: #8960ff;
	--primary-inverted: #ffffff;
	--transition-time: 0.1s;
}

/* Sizing Fixes */
html, body, #root, .archive {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

/* Body Style */
body {
	background: var(--primary);
	color: var(--primary-inverted);
}

a:hover,
a:focus {
	opacity: 0.7;
}
