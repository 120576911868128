.body {
	overflow: unset;
}

.main {
	padding: 1em;
}

/* Header */
.header {
	display: flex;
	align-items: center;

	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;

	margin-top: 0.5rem;
	margin-bottom: 0;

	/* Honestly, don't even ask. This just makes it scale nicely. */
	/*                  largest size                              */
	/*                     |              scaling size            */
	/*                  vvvvvv              |                     */
	/*                  vvvvvv            vvvv                    */
	font-size: calc(min(3.5rem, max(1rem, 11vw)));
	line-height: calc(min(3.5rem, max(1rem, 11vw)));
}

.header svg {
	margin-left: 0.5ch;
}

/* Subheader */
.subheader {
	font-family: 'Switzer', sans-serif;
	text-transform: uppercase;
}

.subheader a {
	display: inline-flex;
	align-items: center;
	position: relative;
}


/* Make SVGs Nice */
.header svg,
.subheader svg {
	height: 1em;
	width: auto;
	fill: currentColor;
}

/* Links */
.subheader a {
	color: var(--primary-inverted);
	text-decoration: none;
}

.subheader a > svg {
	margin-left: 0.5ch;
	height: 0.8em;
}

.links {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	list-style: none;
	padding: 0;
}

.links li {
	display: block;
	width: calc(max(min(100vw - 2rem, 475px), 75%)); /* Again, scaling. */

	margin-top: 1.5rem;
}

/* Fancy Animated Link Hover */
a.swipeLink {
	border-bottom: var(--primary-inverted) 1px solid;
	transition: color var(--transition-time) linear;
}

a.swipeLink > * {
	z-index: 2;
}

a.swipeLink::before {
	content: '';

	position: absolute;
	bottom: -1px;
	left: -0.1em;
	right: -0.1em;
	height: 1px;

	z-index: 1;
	will-change: height;
	transition: height var(--transition-time) linear;
	
	background: var(--primary-inverted);
}

a.swipeLink:hover,
a.swipeLink:focus {
	color: var(--primary);
	opacity: unset;
}

a.swipeLink:hover::before,
a.swipeLink:focus::before {
	height: calc(100% + 1px);
}
